import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';
import { AdminReviewOrderComponent } from './admin-review-order/admin-review-order.component';
import { AdminModalReturnOrderComponent } from './admin-modal-return-order/admin-modal-return-order.component';
import { AdminTrackingOrderComponent } from './admin-tracking-order/admin-tracking-order.component';
import { CalendarOrderDatesComponent } from './calendar-order-dates/calendar-order-dates.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AdminOrdersRoutingModule } from './admin-orders-routing.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ModalFulfilledPercentageOrderComponent } from '@core/shared/feature/modal-fulfilled-percentage-order/modal-fulfilled-percentage-order.component';
import { BadgeModule } from 'primeng/badge';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { OrderListModule } from 'primeng/orderlist';

@NgModule({
  declarations: [
    AdminReviewOrderComponent,
    AdminModalReturnOrderComponent,
    AdminTrackingOrderComponent,
    CalendarOrderDatesComponent,
  ],
  imports: [
    CommonModule,
    AdminOrdersRoutingModule,
    ModalFulfilledPercentageOrderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    FullCalendarModule,
    BadgeModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    InputTextareaModule,
    OrderListModule,
    ProgressSpinnerModule,
    TimelineModule,
  ],
})
export class AdminOrdersModule {}

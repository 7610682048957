import { Component, OnInit } from '@angular/core';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { add, format } from 'date-fns';

@Component({
  selector: 'app-calendar-order-dates',
  templateUrl: './calendar-order-dates.component.html',
  styleUrls: ['./calendar-order-dates.component.scss'],
})
export class CalendarOrderDatesComponent implements OnInit {
  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    headerToolbar: {
      start: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      center: 'title',
      end: 'prev,next today',
    },
    initialView: 'dayGridMonth',
    initialEvents: [
      {
        id: '1',
        title: 'All-day event',
        date: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd') + 'T08:00:00',
      },
      {
        id: '2',
        title: 'Glory Christ Church',
        date: format(new Date(), 'yyyy-MM-dd') + 'T09:00:00',
      },
      {
        id: '3',
        title: 'All-day event',
        date: format(add(new Date(), { days: 3 }), 'yyyy-MM-dd') + 'T08:30:00',
      },
      {
        id: '4',
        title: 'Only Church Of Christ',
        date: format(new Date(), 'yyyy-MM-dd') + 'T11:30:00',
      },
      {
        id: '5',
        title: 'All-day event',
        date: format(new Date(), 'yyyy-MM-dd') + 'T12:00:00',
      },
      {
        id: '6',
        title: 'All-day event',
        date: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd') + 'T09:00:00',
      },
      {
        id: '7',
        title: 'All-day event',
        date: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd') + 'T10:00:00',
      },
      {
        id: '8',
        title: 'All-day event',
        date: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd') + 'T10:30:00',
      },
      {
        id: '9',
        title: 'All-day event',
        date: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd') + 'T11:30:00',
      },
      {
        id: '10',
        title: 'New Hope Baptist Sparkman',
        date: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd') + 'T12:30:00',
      },
    ], // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: true,
    dayMaxEvents: true,
    eventClick: this.handleEventClick.bind(this),
  };

  constructor() {}

  ngOnInit(): void {}

  handleEventClick(selectedEvent: EventClickArg) {}
}
